import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "sticky top-0 left-0 z-10" }
const _hoisted_2 = { class: "min-h-fit min-w-full bg-zinc-900 border-b-[1px] border-zinc-700" }
const _hoisted_3 = { class: "flex py-2 md:w-11/12 md:mx-auto md:max-w-[70rem] items-center" }
const _hoisted_4 = {
  key: 0,
  class: "ml-auto flex"
}
const _hoisted_5 = {
  key: 1,
  class: "ml-auto flex text-zinc-200 p-2 font-semibold mx-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'home' },
            class: "nav-button"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Home")
            ])),
            _: 1
          })
        ]),
        (!_ctx.userData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  to: { name: 'signin' },
                  class: "nav-button"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("Sign In")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  to: { name: 'signup' },
                  class: "signup bg-matte-blue text-white rounded-xl hover:bg-white hover:text-matte-medium-blue"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Sign Up")
                  ])),
                  _: 1
                })
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  to: { name: 'dashboard' },
                  class: "nav-button"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.userData.username), 1)
                  ]),
                  _: 1
                })
              ])
            ]))
      ])
    ])
  ]))
}